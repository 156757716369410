var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "myWangEditor" },
    [
      _c("div", {
        staticStyle: { height: "calc(100%)" },
        attrs: { id: _vm.id },
      }),
      _c(
        "Modal",
        {
          attrs: { title: "编辑html代码", "mask-closable": false, width: 900 },
          model: {
            value: _vm.showHTMLModal,
            callback: function ($$v) {
              _vm.showHTMLModal = $$v
            },
            expression: "showHTMLModal",
          },
        },
        [
          _c("Input", {
            staticStyle: { "max-height": "60vh", overflow: "auto" },
            attrs: { rows: 20, type: "textarea" },
            model: {
              value: _vm.dataEdit,
              callback: function ($$v) {
                _vm.dataEdit = $$v
              },
              expression: "dataEdit",
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.showHTMLModal = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "Button",
                {
                  attrs: {
                    type: "primary",
                    icon: "md-checkmark-circle-outline",
                  },
                  on: { click: _vm.editHTMLOk },
                },
                [_vm._v("确定保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }